import { translation } from '@/helpers/TranslationHelper';

export const physicalActivityData = [
    {
        id: 1,
        title: translation('main.calorieSection.sedentary'),
        description: translation('main.calorieSection.sedentaryDescription'),
        value: 'sedentary',
    },
    {
        id: 2,
        title: translation('main.calorieSection.light'),
        description: translation('main.calorieSection.lightDescription'),
        value: 'lightly',
    },
    {
        id: 3,
        title: translation('main.calorieSection.moderate'),
        description: translation('main.calorieSection.moderateDescription'),
        value: 'moderately',
    },
    {
        id: 4,
        title: translation('main.calorieSection.veryActive'),
        description: translation('main.calorieSection.veryActiveDescription'),
        value: 'very',
    },
    {
        id: 5,
        title: translation('main.calorieSection.extraActive'),
        description: translation('main.calorieSection.extraActiveDescription'),
        value: 'extremely',
    },
];
