<template>
    <div class="preview-section">
        <div class="content__wrap">
            <div class="content__wrapper">
                <h1>{{ $t('main.previewSection.title') }}</h1>
                <CRButton @click="toMealKitPage">{{ $t('buttons.orderNow') }}</CRButton>
            </div>
            <div class="content__wrapper">
                <h1>12 patiekalų Kūčių vakarienės rinkinys tiesiai į namus🎄</h1>
                <CRButton @click="toCristmasPage">{{ $t('buttons.orderNow') }}</CRButton>
            </div>
        </div>
        <!-- <img src="@/assets/images/backgrounds/hero-xmas.jpg" alt="preview-section-bg" class="preview-section-bg" /> -->
        <img src="@/assets/images/backgrounds/mobile-hero-bg.png" alt="preview-section-bg" class="preview-section-bg" />
        <div class="promotion__wrapper" v-if="$filters.getTranslationByKey(promotionText?.text)">
            <h4>{{ $filters.getTranslationByKey(promotionText?.text) }}</h4>
        </div>
        <div class="bg-block" />
    </div>
</template>

<script>
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import { PromotionTextApi } from '@/api';

export default {
    name: 'PreviewSection',
    components: { CRButton },
    data() {
        return {
            promotionText: null,
        };
    },
    methods: {
        toCristmasPage() {
            this.$router.push({ name: 'mealKit', params: { meal_kit_id: 6 } })
        },
        toMealKitPage() {
            this.$router.push({ name: 'mealKits' });
        },

        async getPromotionText() {
            try {
                const response = await PromotionTextApi.get();
                this.promotionText = response.data;
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    },
    async mounted() {
        await this.getPromotionText();
    },
};
</script>

<style lang="scss" scoped>
.preview-section {
    padding-top: 45px;
    padding-bottom: 95px;
    position: relative;

    .content__wrap {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 1100px) {
            flex-wrap: wrap;
        }
    }

    .content__wrapper {

        margin-bottom: 20px;

        @media screen and (max-width: 500px) {
            margin-bottom: 20px;
        }

        h1 {
            font-size: $font-26;
            margin-bottom: 25px;
            min-height: 134px;
            max-width: 262px;

            @media screen and (max-width: 500px) {
                min-height: 104px;
            }
        }
    }

    .preview-section-bg {
        position: absolute;
        top: 0;
        right: -15px;

        z-index: -1;
    }

    .plate-bg {
        display: none;
    }

    .promotion__wrapper {
        position: absolute;
        bottom: 20px;

        width: 100%;

        padding: 15px;
        border-radius: $br-10;
        background: rgba(0, 167, 85, 0.7);

        @media screen and (max-width: 500px) {
            position: static;
        }

        h4 {
            color: $white;
            text-align: center;
        }
    }
}

@include media($lg) {
    .preview-section {
        @include row-align-center-justify-between;

        padding-bottom: 50px;

        .content__wrapper {
            h1 {
                font-size: $font-34;
                margin-bottom: 24px;

                max-width: 347px;
            }
        }

        .preview-section-bg {
            display: none;
        }

        .promotion__wrapper {
            width: auto;
            max-width: 420px;

            top: 45px;
            bottom: unset;
            right: 0;

            border-radius: 0 0 0 10px;
        }

        .bg-block {
            // background: url('../../assets/images/backgrounds/hero.png') center no-repeat;
            // background: url('../../assets/images/backgrounds/hero-xmas.png') center no-repeat;
            background: url('../../assets/images/backgrounds/cityrush_2024.jpg') center no-repeat;
            // background: url('../../assets/images/backgrounds/CITY_RUSH_DEC2023-8-min.jpg') center no-repeat;
            background-size: cover;
            height: 520px;
            width: 70%;
            border-radius: $br-10;
        }
    }
}
</style>
