import { translation } from '@/helpers/TranslationHelper';
import { WeightChangeEnum } from '@/utils/enums';

export const sectionButtons = (selectedSection) => {
    return [
        {
            id: 1,
            title: translation('buttons.weightMaintain'),
            value: WeightChangeEnum.MAINTAIN,
            isActive: selectedSection === WeightChangeEnum.MAINTAIN,
        },
        {
            id: 2,
            title: translation('buttons.weightLoss'),
            value: WeightChangeEnum.LOSS,
            isActive: selectedSection === WeightChangeEnum.LOSS,
        },
        {
            id: 1,
            title: translation('buttons.weightGain'),
            value: WeightChangeEnum.GAIN,
            isActive: selectedSection === WeightChangeEnum.GAIN,
        },
    ];
};

export const weightLossData = [
    {
        id: 1,
        title: translation('main.calorieSection.mildWeightLoss'),
        value: 'mild',
        kgAmount: 0.25,
        kcalAmount: 1900,
    },
    {
        id: 2,
        title: translation('main.calorieSection.weightLoss'),
        value: 'mid',
        kgAmount: 0.5,
        kcalAmount: 1750,
    },
    {
        id: 3,
        title: translation('main.calorieSection.extremeWeightLoss'),
        value: 'extreme',
        kgAmount: 1,
        kcalAmount: 1500,
    },
];

export const weightGainData = [
    {
        id: 1,
        title: translation('main.calorieSection.mildWeightGain'),
        value: 'mild',
        kgAmount: 0.25,
        kcalAmount: 2000,
    },
    {
        id: 2,
        title: translation('main.calorieSection.weightGain'),
        value: 'mid',
        kgAmount: 0.5,
        kcalAmount: 1900,
    },
    {
        id: 3,
        title: translation('main.calorieSection.extremeWeightGain'),
        value: 'extreme',
        kgAmount: 1,
        kcalAmount: 1750,
    },
];
