<template>
    <div class="general-info">
        <h3>{{ $t('main.calorieSection.generalInformation') }}</h3>
        <div class="radio__wrapper">
            <CRRadio class="radio-btn" value="male" v-model="form.sex">
                {{ $t('common.male') }}
            </CRRadio>
            <CRRadio value="female" v-model="form.sex">
                {{ $t('common.female') }}
            </CRRadio>
        </div>
        <CRInput
            class="info-input"
            v-for="input in generalInfoInputsData"
            :key="input.id"
            v-model="form[input.value]"
            type="number"
            :label="input.label"
            :placeholder="input.placeholder"
            required
            :errors="errors[input.errors]"
            @onChange="$filters.clearError(errors, input.errors)"
        />
        <RequiredText class="tooltip" />
        <CRButton @click="nextStep"> {{ $t('buttons.physicalActivity') }}</CRButton>
    </div>
</template>

<script>
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';
    import LocalStorage from '@/helpers/LocalStorageHelper';
    import { generalInfoInputsData } from '@/config/caloriesCalc/generalInfo';
    import { validateForm } from '@/helpers/ValidationRulesHelper';

    export default {
        name: 'GeneralInfo',
        components: { CRRadio, CRInput, CRButton, RequiredText },
        emits: ['nextStep'],
        data() {
            return {
                generalInfoInputsData,
                form: {
                    sex: 'male',
                    age: '',
                    height: '',
                    weight: '',
                },
                errors: {},
            };
        },
        created() {
            if (LocalStorage.show('generalInfo')) {
                this.form = LocalStorage.show('generalInfo');
            }
        },
        methods: {
            nextStep() {
                const validateFormData = validateForm(this.form, this.errors);
                this.errors = validateFormData.errorState;

                if (validateFormData.isValid) {
                    LocalStorage.create('generalInfo', this.form);

                    this.$emit('nextStep', 1);
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .general-info {
        width: 100%;

        h3 {
            font-size: $font-18;
            margin-bottom: 26px;

            @include media($lg) {
                font-size: $font-20;
            }
        }

        .radio__wrapper {
            @include row-align-start;

            margin-bottom: 26px;

            .radio-btn {
                margin-right: 26px;
            }
        }

        .info-input {
            margin-bottom: 27px;

            &:last-child {
                margin-bottom: 12px;
            }
        }

        .tooltip {
            margin-bottom: 26px;
        }
    }
</style>
