<template>
    <ul class="accordion">
        <slot />
    </ul>
</template>

<script>
    export default {
        name: 'Accordion',
        data() {
            return {
                Accordion: {
                    count: 0,
                    active: null,
                },
            };
        },
        provide() {
            return { Accordion: this.Accordion };
        },
    };
</script>
