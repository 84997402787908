<template>
    <div class="results">
        <h3>{{ $t('main.calorieSection.results') }}</h3>
        <div class="tag__wrapper">
            <CRTag v-for="(item, index) in Object.entries(infoData)" :key="index" class="info-tag" type="additional">
                <span :class="{ sex: item[0] === 'sex' }">
                    {{ item[1] }}
                    <template v-if="item[0] === 'age'"> {{ $t('common.years') }} </template>
                    <template v-if="item[0] === 'height'"> {{ $t('common.cm') }} </template>
                    <template v-if="item[0] === 'weight'"> {{ $t('common.kg') }} </template>
                </span>
            </CRTag>
            <CRTag class="activity-tag" type="additional">
                <span class="title">{{ activity.title }}</span>
                <span>{{ activity.description }}</span>
            </CRTag>
        </div>
        <div class="btn__wrapper">
            <CRButton
                class="btn"
                v-for="button in sectionButtons(selectedSection)"
                :key="button.id"
                :active="button.isActive"
                @click="selectedSection = button.value"
                pattern="section"
            >
                {{ button.title }}
            </CRButton>
        </div>
        <h4 v-if="selectedSection !== WeightChangeEnum.MAINTAIN">{{ $t('main.calorieSection.chooseSuitOption') }}</h4>
        <div class="maintain-section" v-if="selectedSection === WeightChangeEnum.MAINTAIN">
            <RecommendedKitsWrapper
                :mealKits="mealKits"
                :calories-amount="calculatedCalories.maintain"
                :selected-section="selectedSection"
                :kcal-amount="calculatedCalories.maintain"
            />
        </div>
        <div v-else>
            <CRRadio
                v-for="item in weightData"
                :key="item.id"
                v-model="weightChangeType"
                is-box
                :value="item.value"
                class="radio-card"
            >
                {{ item.title }}
                <template #content>
                    <RecommendedKitsWrapper
                        :value="item.value"
                        :kg-amount="item.kgAmount"
                        :selected-section="selectedSection"
                        :calories-amount="calculatedCalories[item.value]"
                        :kcal-amount="calculatedCalories[item.value]"
                        :current-weight-change-type="weightChangeType"
                        :meal-kits="mealKits"
                    />
                </template>
            </CRRadio>
            <p
                v-if="selectedSection === WeightChangeEnum.LOSS && weightChangeType === WeightChangeGoalsEnum.EXTREME"
                class="info-text"
            >
                {{ $t('main.calorieSection.extremeWeightLossInfo') }}
            </p>
        </div>

        <CRButton class="change-btn" pattern="outline" @click="changeParameter">
            {{ $t('buttons.changeParameters') }}
        </CRButton>
    </div>
</template>

<script>
    import { sectionButtons, weightLossData, weightGainData } from '@/config/caloriesCalc/results';
    import { physicalActivityData } from '@/config/caloriesCalc/physicalActivity';
    import { calculateCalorie } from '@/helpers/CaloriesCalculatorHelper';
    import RecommendedKitsWrapper from '@/components/CaloriesCalc/RecommendedKitsWrapper.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import LocalStorage from '@/helpers/LocalStorageHelper';
    import { MealKitApi } from '@/api';
    import { WeightChangeEnum, WeightChangeGoalsEnum } from '@/utils/enums';
    import { cloneDeep } from 'lodash';

    export default {
        name: 'CalcResults',
        components: { CRTag, CRButton, RecommendedKitsWrapper, CRRadio },
        emits: ['nextStep'],
        data() {
            return {
                physicalActivityData,
                weightLossData,
                weightGainData,
                WeightChangeEnum,
                WeightChangeGoalsEnum,
                sectionButtons,
                activity: null,
                infoData: null,
                selectedSection: WeightChangeEnum.MAINTAIN,
                mealKits: [],
                weightChangeType: WeightChangeGoalsEnum.MILD,

                dataForCalculation: {
                    weight: '',
                    height: '',
                    age: '',
                    sex: '',
                    activity: '',
                    weightChange: {
                        purpose: '',
                        type: '',
                    },
                },

                mealKitsForCalculations: {
                    mild: null,
                    mid: null,
                    extreme: null,
                },

                calculatedCalories: {
                    maintain: null,
                    mild: null,
                    mid: null,
                    extreme: null,
                },
            };
        },
        watch: {
            async selectedSection(val) {
                this.dataForCalculation.weightChange.purpose = val;
                const dataObject = cloneDeep(this.dataForCalculation);

                if (val === WeightChangeEnum.MAINTAIN) {
                    this.mealKits = await this.getRecommended(dataObject);
                } else {
                    for (const key of Object.keys(this.mealKitsForCalculations)) {
                        dataObject.weightChange.type = key;
                        this.mealKitsForCalculations[key] = await this.getRecommended(dataObject);
                    }
                    this.mealKits = this.mealKitsForCalculations[this.weightChangeType];
                }
            },
            async weightChangeType(val) {
                this.dataForCalculation.weightChange.type = val;

                if (this.selectedSection !== WeightChangeEnum.MAINTAIN) {
                    this.mealKits = this.mealKitsForCalculations[val];
                }
            },
        },
        computed: {
            weightData() {
                if (this.selectedSection === WeightChangeEnum.LOSS) {
                    return this.weightLossData;
                }

                if (this.selectedSection === WeightChangeEnum.GAIN) {
                    return this.weightGainData;
                }

                return [];
            },
        },
        async created() {
            this.getDataFromLocalStorage();

            this.dataForCalculation = {
                weight: this.infoData.weight,
                height: this.infoData.height,
                age: this.infoData.age,
                sex: this.infoData.sex,
                activity: this.activity.value,
                weightChange: {
                    purpose: this.selectedSection,
                    type: WeightChangeGoalsEnum.MILD,
                },
            };
            this.mealKits = await this.getRecommended(this.dataForCalculation);
        },

        methods: {
            getDataFromLocalStorage() {
                if (LocalStorage.show('generalInfo')) {
                    this.infoData = LocalStorage.show('generalInfo');
                }

                if (LocalStorage.show('activity')) {
                    this.activity = physicalActivityData.find(
                        (activity) => activity.value === LocalStorage.show('activity')
                    );
                }
            },

            async getRecommended(data) {
                try {
                    const caloriesData = calculateCalorie(data);

                    const calorieField =
                        data.weightChange.purpose === WeightChangeEnum.MAINTAIN
                            ? WeightChangeEnum.MAINTAIN
                            : data.weightChange.type;
                    this.calculatedCalories[calorieField] = caloriesData.calories;

                    const response = await MealKitApi.getRecommended(caloriesData);
                    return response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            changeParameter() {
                LocalStorage.delete('activity');
                LocalStorage.delete('generalInfo');

                this.$emit('nextStep', 0);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .results {
        width: 100%;

        h3 {
            font-size: $font-18;
            margin-bottom: 18px;
        }

        .tag__wrapper {
            @include row-align-center;

            flex-wrap: wrap;

            margin-bottom: 16px;

            .info-tag {
                margin-right: 6px;

                span {
                    font-size: $font-14;
                    color: $secondary;

                    &.sex {
                        text-transform: capitalize;
                    }
                }
            }

            .activity-tag {
                width: 100%;
                margin-top: 4px;
                text-align: start;
                padding: 8px 10px;

                span {
                    font-size: $font-14;
                    font-weight: 400;
                }

                .title {
                    font-weight: 700;
                    margin-right: 5px;
                }
            }
        }

        .btn__wrapper {
            @include row-align-center;

            flex-wrap: wrap;

            .btn {
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }

        h4 {
            margin-top: 8px;
            margin-bottom: 16px;
        }

        .maintain-section {
            margin-top: 20px;
        }

        .radio-card {
            margin-bottom: 15px;
        }

        .info-text {
            font-style: italic;
            font-size: $font-14;
        }

        .change-btn {
            margin-top: 12px;
        }
    }

    @include media($lg) {
        .results {
            width: 100%;

            h3 {
                font-size: $font-20;
                margin-bottom: 14px;
            }

            .tag__wrapper {
                margin-bottom: 30px;

                .activity-tag span {
                    font-size: $font-16;
                }
            }

            .change-btn {
                margin-top: 20px;
            }
        }
    }
</style>
