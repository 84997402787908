<template>
    <div class="calorie-calc-section">
        <h2>{{ $t('main.calorieSection.title') }}</h2>
        <div class="calculator__wrapper">
            <StepProgress :steps="steps" :current-step="currentStep" class="progress-bar" />
            <GeneralInfo v-if="currentStep === 0" @nextStep="nextStep" />
            <PhysicalActivity v-if="currentStep === 1" @nextStep="nextStep" />
            <CalcResults v-if="currentStep === 2" @nextStep="nextStep" />
        </div>
    </div>
</template>

<script>
    import StepProgress from '@/components/Common/StepProgress/StepProgress.vue';
    import GeneralInfo from '@/components/CaloriesCalc/GeneralInfo.vue';
    import PhysicalActivity from '@/components/CaloriesCalc/PhysicalActivity.vue';
    import CalcResults from '@/components/CaloriesCalc/CalcResults.vue';

    export default {
        name: 'CalorieCalcSection',
        components: { StepProgress, GeneralInfo, PhysicalActivity, CalcResults },
        data() {
            return {
                steps: [
                    this.$t('main.calorieSection.parameters'),
                    this.$t('main.calorieSection.activity'),
                    this.$t('main.calorieSection.results'),
                ],
                currentStep: 0,
            };
        },
        methods: {
            nextStep(step) {
                this.currentStep = step;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .calorie-calc-section {
        .calculator__wrapper {
            @include column-align-start-justify-between;

            .progress-bar {
                width: 70%;
                margin-bottom: 16px;
            }
        }
    }

    @include media($lg) {
        .calorie-calc-section {
            h2 {
                margin-bottom: 24px;
            }

            .calculator__wrapper .progress-bar {
                width: 80%;
                margin-bottom: 40px;
            }

            .calculator__wrapper {
                box-shadow: $box-shadow-dark;
                border-radius: $br-10;

                padding: 30px 55px;
            }
        }
    }

    @include media($xxl) {
        .calorie-calc-section .calculator__wrapper .progress-bar {
            width: 88%;
            margin-bottom: 40px;
        }
    }
</style>
