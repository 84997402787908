<template>
    <div class="main-container">
        <PreviewSection class="preview-section" />
        <TimerSection v-if="timer && isTimerAvailable" class="timer-section" />
        <MealKitsSection class="meal-kits-section" />
        <HowItWorksSection class="work-section" />
        <CalorieCalcSection class="calc-section" />
        <BlogSection class="blog-section" />
        <FAQSection class="faq-section" />
        <GetInTouchSection class="get-in-touch-section" />
    </div>
</template>

<script>
    import PreviewSection from '@/components/MainSections/PreviewSection.vue';
    import TimerSection from '@/components/MainSections/TimerSection.vue';
    import MealKitsSection from '@/components/MainSections/MealKitsSection.vue';
    import HowItWorksSection from '@/components/MainSections/HowItWorksSection.vue';
    import CalorieCalcSection from '@/components/MainSections/CalorieCalcSection.vue';
    import BlogSection from '@/components/MainSections/BlogSection.vue';
    import FAQSection from '@/components/MainSections/FAQSection.vue';
    import GetInTouchSection from '@/components/MainSections/GetInTouchSection.vue';
    import LocalStorage from '@/helpers/LocalStorageHelper';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'Main',
        components: {
            PreviewSection,
            TimerSection,
            MealKitsSection,
            HowItWorksSection,
            CalorieCalcSection,
            BlogSection,
            FAQSection,
            GetInTouchSection,
        },
        computed: {
            ...mapGetters('timer', ['timer', 'isTimerAvailable']),
        },
        methods: {
            ...mapActions('timer', ['getTimer']),
        },
        async mounted() {
            try {
                await this.getTimer();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
        unmounted() {
            LocalStorage.delete('activity');
            LocalStorage.delete('generalInfo');
        },
    };
</script>

<style lang="scss" scoped>
    .main-container {
        overflow: hidden;

        padding-bottom: 50px;

        .blog-section {
            margin-top: 30px;
        }

        .meal-kits-section,
        .work-section,
        .calc-section,
        .faq-section {
            margin-top: 40px;
        }

        .get-in-touch-section {
            margin-top: 24px;
        }
    }

    @include media($md) {
        .main-container {
            @include row-align-start-justify-between;

            flex-wrap: wrap;

            overflow: hidden;

            .preview-section,
            .timer-section,
            .meal-kits-section {
                width: 100%;
            }

            .work-section,
            .calc-section {
                width: 49%;
            }
        }
    }

    @include media($lg) {
        .main-container {
            .meal-kits-section,
            .blog-section,
            .faq-section,
            .get-in-touch-section {
                margin-top: 50px;
            }
        }
    }
</style>
