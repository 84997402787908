<template>
    <li class="accordion__item">
        <div class="accordion__trigger" @click="open">
            <p class="w-90">{{ title }}</p>
            <div class="buttons-container">
                <CRButton v-if="editable" size="x-small" color="danger" @click.stop="handleItemDelete">
                    {{ $t('buttons.delete') }}
                </CRButton>
                <CRButton v-if="editable" size="x-small" @click.stop="handleItemEdit">
                    {{ $t('buttons.update') }}
                </CRButton>
                <CRButton :plus="!visible" :minus="visible" />
            </div>
        </div>

        <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
            <div class="accordion__content" :class="{ 'accordion__content-active': visible }" v-show="visible">
                <div class="w-90" v-html="text" />
            </div>
        </transition>
    </li>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';

    export default {
        name: 'AccordionItem',
        components: { CRButton },
        inject: ['Accordion'],
        props: {
            title: {
                type: String,
                default: '',
            },
            text: {
                type: String,
                default: '',
            },
            editable: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['edit', 'delete'],
        data() {
            return {
                index: null,
            };
        },
        computed: {
            visible() {
                return this.index === this.Accordion.active;
            },
        },
        methods: {
            open() {
                if (this.visible) {
                    this.Accordion.active = null;
                } else {
                    this.Accordion.active = this.index;
                }
            },
            handleItemEdit() {
                this.$emit('edit', this.$.vnode.key);
            },
            handleItemDelete() {
                this.$emit('delete', this.$.vnode.key);
            },
            start(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            end(el) {
                el.style.height = '';
            },
        },
        created() {
            this.index = this.Accordion.count++;
        },
    };
</script>

<style lang="scss" scoped>
    .accordion__item {
        cursor: pointer;

        padding: 5px;

        .accordion__trigger {
            @include row-align-center-justify-between;

            p {
                font-weight: 700;
            }

            margin-bottom: 20px;
        }

        .accordion__content-active {
            margin-bottom: 20px;
        }
    }

    .buttons-container {
        @include row-align-center;

        button {
            margin-right: 5px;
        }
    }

    // animation
    .accordion-enter-active,
    .accordion-leave-active {
        will-change: height, opacity;
        transition: height 0.3s ease, opacity 0.3s ease;
        overflow: hidden;
    }

    .accordion-enter-from,
    .accordion-leave-to {
        height: 0 !important;
        opacity: 0;
    }
</style>
