<template>
    <div class="blog-section" v-if="blogArticles.length">
        <h3>{{ $t('main.blogSection.title') }}</h3>
        <p>{{ $t('main.blogSection.subTitle') }}</p>
        <Slider
            :slides-per-view="provideApp.$screenInnerWidth <= 1024 ? 1 : 3"
            :space-between="provideApp.$screenInnerWidth <= 1024 ? 10 : 1"
        >
            <SwiperSlide v-for="blogArticle in blogArticles" :key="blogArticle.id">
                <BlogCard
                    class="blog-wrapper"
                    :id="blogArticle.id"
                    @click="onBlogArticleClick(blogArticle.id)"
                    :title="$filters.getTranslationByKey(blogArticle.title)"
                    :image="blogArticle.pictures[0]?.picturePath.thumbnail"
                    :date="blogArticle.createdAt"
                />
            </SwiperSlide>
        </Slider>
        <CRButton class="btn" @click="moreArticles">{{ $t('buttons.moreArticles') }}</CRButton>
    </div>
</template>

<script>
    import BlogCard from '@/components/BlogArticles/BlogCard.vue';
    import { SwiperSlide } from 'swiper/vue/swiper-slide';
    import Slider from '@/components/Common/Slider/Slider.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'BlogSection',
        components: { Slider, CRButton, SwiperSlide, BlogCard },
        inject: ['provideApp'],
        computed: {
            ...mapGetters('blogArticle', ['blogArticles']),
        },
        methods: {
            ...mapActions('blogArticle', ['getAllBlogArticles']),

            onBlogArticleClick(id) {
                this.$router.push({
                    name: 'blogArticle',
                    params: { blog_article_id: id },
                });
            },

            moreArticles() {
                this.$router.push({ name: 'blogArticles' });
            },
        },
        async mounted() {
            try {
                await this.getAllBlogArticles();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .blog-section {
        width: 100%;

        p {
            margin-top: 10px;
            margin-bottom: 10px;

            font-weight: 700;
            opacity: 0.7;
        }

        .blog-wrapper {
            height: 300px;
        }

        .btn {
            margin-top: 18px;
        }
    }

    @include media($lg) {
        .blog-section {
            h3 {
                font-size: $font-24;
            }

            p {
                margin-top: 8px;
            }

            .blog-wrapper {
                height: 300px;
                margin-right: 2%;
            }

            .btn {
                margin-top: 0;
            }
        }
    }

    // slider
    :deep(.slider) {
        padding: 10px 5px 40px 5px;
    }

    :deep(.swiper-slide) {
        width: 100% !important;

        @include row-align-center-justify-center;
    }

    :deep(.slider .swiper-pagination-bullet) {
        text-align: center;
        line-height: 40px;
        font-size: 24px;
        opacity: 1;
        background: $grey-form;
    }
    :deep(.slider .swiper-pagination-bullet-active) {
        background: $primary;
    }

    :deep(.swiper-pagination) {
        bottom: 0px;
    }

    @include media($lg) {
        :deep(.swiper-slide) {
            width: 33.5% !important;

            @include row-align-center-justify-between;
        }

        :deep(.swiper-pagination) {
            display: block;
        }
    }
</style>
