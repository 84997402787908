<template>
    <div class="how-it-works-section" :class="{'how-it-works-section__horizontal' : horizontal}">
        <h2>{{ $t('main.howItWorksSection.title') }}</h2>
        <div class="block">
            <div class="item">
                <ForkSvg />
                <h5>{{ $t('main.howItWorksSection.step1') }}</h5>
            </div>

            <div class="item">
                <BoxSvg />
                <h5>{{ $t('main.howItWorksSection.step2') }}</h5>
            </div>

            <div class="item">
                <TruckSvg />
                <h5>{{ $t('main.howItWorksSection.step3') }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
    import BoxSvg from '@/assets/icons/how-it-works/box.svg?inline';
    import ForkSvg from '@/assets/icons/how-it-works/fork.svg?inline';
    import TruckSvg from '@/assets/icons/how-it-works/truck.svg?inline';

    export default {
        name: 'HowItWorksSection',
        components: { BoxSvg, ForkSvg, TruckSvg },
        props: {
            horizontal: {
                type: Boolean,
                default: false,
            }
        }
    };
</script>

<style lang="scss" scoped>
    .how-it-works-section {
        @include column-align-start;

        &__horizontal {
            .block {
                flex-direction: row !important;
                gap: 30px;
                min-height: inherit !important;
                @media screen and (max-width: 900px) {
                    flex-wrap: wrap;
                }
            }
        }

        h2 {
            margin-bottom: 16px;

            @include media($lg) {
                margin-bottom: 24px;
            }
        }

        .block {
            @include column-align-center-justify-center;

            background-color: $primary-500;

            background-image: url('../../assets/icons/how-it-works/bg.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            height: 100%;
            width: 100%;

            min-height: 432px;

            padding: 50px 0;

            border-radius: $br-20;

            @include media($lg) {
                min-height: 620px;
            }

            .item {
                @include column-align-center-justify-center;

                max-width: 211px;

                margin-bottom: 50px;

                &:last-child {
                    margin-bottom: 0;
                }

                h5 {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }
    }
</style>
