import { MIN_AGE, MAX_AGE } from '@/helpers/ValidationRulesHelper';
import { translation } from '@/helpers/TranslationHelper';

export const generalInfoInputsData = [
    {
        id: 1,
        value: 'age',
        label: translation('common.ageFrom', { MIN_AGE, MAX_AGE }),
        placeholder: translation('placeholder.enterAge', { MIN_AGE, MAX_AGE }),
        errors: 'age',
    },
    {
        id: 2,
        value: 'height',
        label: translation('common.heightCm'),
        placeholder: translation('placeholder.enterHeight'),
        errors: 'height',
    },
    {
        id: 3,
        value: 'weight',
        label: translation('common.weightKg'),
        placeholder: translation('placeholder.enterWeight'),
        errors: 'weight',
    },
];
