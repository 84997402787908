<template>
    <div class="physical-activity">
        <h3>{{ $t('main.calorieSection.physicalActivity') }}</h3>
        <CRRadio
            v-for="activityItem in physicalActivityData"
            :key="activityItem.id"
            class="radio-btn"
            :value="activityItem.value"
            v-model="activity"
            is-box
            :title="activityItem.title"
            :description="activityItem.description"
        />
        <p>
            <span>{{ $t('main.calorieSection.exercise') }}</span>
            {{ $t('main.calorieSection.exerciseDescription') }}
        </p>
        <p>
            <span>{{ $t('main.calorieSection.intenseExercise') }}</span>
            {{ $t('main.calorieSection.intenseExerciseDescription') }}
        </p>
        <p>
            <span>{{ $t('main.calorieSection.veryIntenseExercise') }}</span>
            {{ $t('main.calorieSection.veryIntenseExerciseDescription') }}
        </p>
        <div class="btn__wrapper">
            <CRButton @click="$emit('nextStep', 0)" class="btn-back" pattern="outline">
                {{ $t('buttons.back') }}
            </CRButton>
            <CRButton @click="$emit('nextStep', 2)">
                {{ $t('buttons.results') }}
            </CRButton>
        </div>
    </div>
</template>
<script>
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import LocalStorage from '@/helpers/LocalStorageHelper';
    import { physicalActivityData } from '@/config/caloriesCalc/physicalActivity';

    export default {
        name: 'PhysicalActivity',
        components: { CRRadio, CRButton },
        emits: ['nextStep'],
        data() {
            return {
                activity: '',
                physicalActivityData,
            };
        },
        watch: {
            activity(value) {
                LocalStorage.create('activity', value);
            },
        },
        created() {
            if (LocalStorage.show('activity')) {
                this.activity = LocalStorage.show('activity');
            } else {
                LocalStorage.create('activity', 'sedentary');
                this.activity = 'sedentary';
            }
        },
    };
</script>
<style lang="scss" scoped>
    .physical-activity {
        width: 100%;

        h3 {
            font-size: $font-18;
            margin-bottom: 24px;

            @include media($lg) {
                font-size: $font-20;
            }
        }

        .radio-btn {
            margin-bottom: 20px;

            @include media($lg) {
                margin-bottom: 12px;
            }
        }

        p {
            margin-bottom: 12px;
            font-size: $font-14;

            span {
                font-size: $font-14;
                font-weight: 700;
            }

            &:last-child {
                margin-bottom: 16px;
            }
        }

        .btn__wrapper {
            @include row-align-center;

            .btn-back {
                margin-right: 10px;
            }
        }
    }
</style>
