<template>
    <div class="meal-kits-section" v-if="allMealKitsWithIO.length">
        <h3>{{ $t('main.mealKitsSection.title') }}</h3>
        <!-- <MealKitRouteCard
            class="card"
            :name="'Kūčių stalo rinkinys'"
            :price="99"
            :isIndividual="true"
            @click="openCristmasForm()"
        /> -->
        <MealKitCard
            class="card"
            @click="openMealKitPage(mealKit)"
            @open-cart="openCart(mealKit)"
            v-for="mealKit in allMealKitsWithIO"
            :key="mealKit.id"
            :id="mealKit.id"
            :name="$filters.getTranslationByKey(mealKit.name)"
            :price="mealKit?.price"
            :meals-amount="mealKit?.mealsAmount"
            :is-vegetarian="mealKit?.isVegetarian"
            :discount-type="mealKit.discount?.type"
            :discount-amount="mealKit.discount?.amount"
            :is-hidden="mealKit.isHidden"
            :image="mealKit.image"
            :is-individual="mealKit.isIndividual"
            :is-unique="mealKit?.isUnique"
            :is-added-to-cart="isAddedToCart(mealKit.id)"
        />
    </div>
</template>

<script>
    import MealKitCard from '@/components/MealKits/MealKitCard.vue';
    // import MealKitRouteCard from '@/components/MealKits/MealKitRouteCard.vue';
    import { cartMixin, openMealKitPageMixin } from '@/utils/mixins';

    export default {
        name: 'MealKitsSection',
        components: { MealKitCard },
        mixins: [cartMixin, openMealKitPageMixin],
        methods: {
            openCristmasForm() {
                this.$router.push({
                    name: 'CristmasEveForm',
                })
            }
        }
    };
</script>

<style lang="scss" scoped>
    .meal-kits-section {
        h3 {
            margin-bottom: 16px;
            width: 100%;
        }

        .card {
            margin-bottom: 10px;
        }
    }

    @include media($md) {
        .meal-kits-section {
            @include row-align-stretch;
            flex-wrap: wrap;

            .card {
                width: 49%;

                margin-right: 2%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($lg) {
        .meal-kits-section {
            h3 {
                margin-bottom: 25px;
            }

            .card {
                width: 32%;
                margin-bottom: 20px;

                &:nth-of-type(2n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }
</style>
