<template>
    <div class="option-wrapper">
        <div class="tag-container">
            <div class="tag-container__inner">
                <h4>{{ sectionTitle }}:</h4>
                <CRTag>{{ kgAmount }} {{ $t('common.kgWeek') }}</CRTag>
            </div>
            <div class="tag-container__inner">
                <h4>{{ $t('main.calorieSection.calorieConsumption') }}:</h4>
                <CRTag>{{ kcalAmount }} {{ $t('common.kcalDay') }}</CRTag>
            </div>
        </div>
        <div v-if="value === currentWeightChangeType">
            <h2>{{ $t('mealKits.recommendedKits') }}</h2>
            <p>({{ $t('main.calorieSection.basedOnCalories') }})</p>
            <div class="card-wrapper-empty" v-if="!mealKits.length && !individualOrderSettings.isHidden">
                <MealKitCard
                    @click="onIndividualOrderClick"
                    class="card"
                    :image="individualOrderSettings.picture?.picturePath"
                    :name="$t('individualOrder.title')"
                    is-recommended
                    is-individual
                />
            </div>
            <div class="card-wrapper" v-else>
                <MealKitCard
                    @click="onMealKitClick(mealKit)"
                    @open-cart="openCart(mealKit)"
                    v-for="mealKit in mealKits"
                    class="card"
                    :class="{ alone: mealKits.length === 1 }"
                    :key="mealKit.id"
                    :name="$filters.getTranslationByKey(mealKit.name)"
                    :meals-amount="mealKit.mealsAmount"
                    :image="mealKit.pictures[0]?.picturePath"
                    :kcal-amount="getSuitableCalorieAmount(mealKit)"
                    :is-added-to-cart="isAddedToCart(mealKit.id)"
                    is-recommended
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import MealKitCard from '@/components/MealKits/MealKitCard.vue';
    import { cartMixin } from '@/utils/mixins';
    import { mapGetters } from 'vuex';
    import { WeightChangeEnum } from '@/utils/enums';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'RecommendedKitsWrapper',
        components: { CRTag, MealKitCard },
        props: {
            value: {
                type: String,
                default: '',
            },
            currentWeightChangeType: {
                type: String,
                default: '',
            },
            selectedSection: {
                type: String,
                default: '',
            },
            mealKits: {
                type: Array,
                default: () => [],
            },
            kgAmount: {
                type: Number,
                default: 0,
            },
            kcalAmount: {
                type: Number,
                default: 2000,
            },
            caloriesAmount: {
                type: Number,
                default: 0,
            },
        },
        mixins: [cartMixin],
        computed: {
            ...mapGetters('mealKit', ['individualOrderSettings']),

            sectionTitle() {
                if (this.selectedSection === WeightChangeEnum.LOSS) {
                    return this.$t('buttons.weightLoss');
                }

                if (this.selectedSection === WeightChangeEnum.GAIN) {
                    return this.$t('buttons.weightGain');
                }

                return this.$t('buttons.weightMaintain');
            },
        },
        methods: {
            getSuitableCalorieAmount(mealKit) {
                const arrayOfAmounts = mealKit.calories.map((calorie) => calorie.amount);

                return this.nearestValue(arrayOfAmounts, this.caloriesAmount);
            },

            nearestValue(arr, val) {
                return arr.reduce(function (prev, curr) {
                    return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
                });
            },

            async onMealKitClick(mealKit) {
                this.$router.push({
                    name: 'mealKit',
                    params: { meal_kit_id: mealKit.id },
                });

                fbq('track', ConversionEventNameEnum.VIEW_CONTENT, {
                    content_ids: [mealKit.id],
                    content_name: mealKit.name,
                    content_type: 'product'
                });

            },

            onIndividualOrderClick() {
                this.$router.push({ name: 'individualOrderKit' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .option-wrapper {
        .tag-container {
            @include row-align-center-justify-between;

            flex-wrap: wrap;

            .tag-container__inner {
                @include row-align-center;

                margin-bottom: 5px;

                h4 {
                    font-size: $font-14;
                    margin-right: 10px;
                }
            }
        }

        h2 {
            margin-top: 10px;

            font-size: $font-18;
        }

        p {
            font-style: italic;
            margin-bottom: 10px;
        }

        .card-wrapper {
            .card {
                margin-bottom: 10px;
            }
        }
    }

    @include media($lg) {
        .option-wrapper {
            h2 {
                font-size: $font-20;
            }

            p {
                margin-bottom: 20px;
            }
        }
    }

    @include media($xl) {
        .option-wrapper {
            .card-wrapper {
                @include row-align-stretch-justify-between;

                flex-wrap: wrap;

                .card {
                    width: 49%;

                    &.alone {
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
