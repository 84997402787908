import { WeightChangeEnum } from '@/utils/enums';

const sexCoefficients = {
    male: 5,
    female: -161,
};

const TDEE = {
    sedentary: 1.2,
    lightly: 1.375,
    moderately: 1.55,
    very: 1.725,
    extremely: 1.9,
};

const weightGoalsAmounts = {
    mild: 250,
    mid: 500,
    extreme: 1000,
};

const weightMultiplier = 10;
const heightMultiplier = 6.25;
const ageMultiplier = 5;
const oneGramInCalories = 7.8;
const daysInWeek = 7;

export const calculateCalorie = ({ weight, height, age, sex, activity, weightChange }) => {
    let neededAmountOfCalories =
        (weightMultiplier * weight + heightMultiplier * height - ageMultiplier * age + sexCoefficients[sex]) *
        TDEE[activity];
    if (weightChange.purpose !== WeightChangeEnum.MAINTAIN) {
        let dailyCaloriesToChange = (weightGoalsAmounts[weightChange.type] * oneGramInCalories) / daysInWeek;
        dailyCaloriesToChange =
            weightChange.purpose === WeightChangeEnum.LOSS ? -dailyCaloriesToChange : dailyCaloriesToChange;
        neededAmountOfCalories += dailyCaloriesToChange;
    }
    return { calories: Math.round(neededAmountOfCalories) };
};
