<template>
    <div class="timer-section">
        <div class="timer-inner" v-if="isLoaded">
            <div class="timer__wrapper" :class="{ danger: isLessThenHour }">
                <div class="time">{{ displayHours }}</div>
                <div>:</div>
                <div class="time">{{ displayMinutes }}</div>
                <div>:</div>
                <div class="time">{{ displaySeconds }}</div>
            </div>
            <h2>{{ $t('main.timerSection.title') }}</h2>
            <h2 class="day">{{ $t(`main.timerSection.day.${timer.getAvailableDay.name}`) }}</h2>
            <CRButton @click="$router.push({ name: 'mealKits' })">
                {{ $t('buttons.orderNow') }}
            </CRButton>
        </div>
        <Vue3Lottie v-else :animationData="SpinPrimary" class="loader" />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import SpinPrimary from '@/assets/animations/spin-primary.json';
    import { mapGetters } from 'vuex';
    import { Vue3Lottie } from 'vue3-lottie';

    export default {
        name: 'TimerSection',
        components: { Vue3Lottie, CRButton },
        data() {
            return {
                SpinPrimary,
                displayDays: 0,
                displayHours: 0,
                displayMinutes: 0,
                displaySeconds: 0,
                timerInterval: null,
                isLessThenHour: false,
                isLoaded: false,
            };
        },
        computed: {
            ...mapGetters('timer', ['timer']),
            seconds: () => 1000,
            minutes() {
                return this.seconds * 60;
            },
            hours() {
                return this.minutes * 60;
            },
            days() {
                return this.hours * 24;
            },
        },
        methods: {
            formatNum: (num) => (num < 10 ? '0' + num : num),

            showRemaining() {
                this.timerInterval = setInterval(() => {
                    const now = new Date();
                    const end = new Date(this.timer.getAvailableDay.date);
                    const endTimeArray = this.timer.displayedEndTime.split(':');


                    end.setHours(endTimeArray[0]);
                    end.setMinutes(endTimeArray[1]);

                    const distance = end.getTime() - now.getTime();
                    if (distance < 0) {
                        clearInterval(this.timerInterval);
                        return;
                    }

                    if (distance <= 3600000) {
                        this.isLessThenHour = true;
                    }

                    const days = Math.floor(distance / this.days);
                    const hours = Math.floor((distance % this.days) / this.hours);
                    const minutes = Math.floor((distance % this.hours) / this.minutes);
                    const seconds = Math.floor((distance % this.minutes) / this.seconds);

                    this.displayDays = this.formatNum(days);
                    this.displayHours = this.formatNum(hours);
                    this.displayMinutes = this.formatNum(minutes);
                    this.displaySeconds = this.formatNum(seconds);

                    this.isLoaded = true;
                }, 1000);
            },
        },
        mounted() {
            this.showRemaining();
        },
        unmounted() {
            clearInterval(this.timerInterval);
        },
    };
</script>

<style lang="scss" scoped>
    .timer-section,
    .timer-inner {
        @include column-align-center-justify-center;
    }

    .timer-section {
        background: $primary-400;
        border-radius: $br-10;

        background-image: url('../../assets/images/backgrounds/counter-bg.png');
        background-repeat: no-repeat;

        padding: 24px 50px;

        .loader {
            width: 300px;
            height: 253px;
        }

        .timer__wrapper {
            @include row-align-center-justify-between;

            font-size: $font-45;
            font-weight: 700;
            margin-bottom: 20px;

            .time {
                @include row-align-center-justify-center;

                margin: 0 5px;

                width: 66px;
            }

            &.danger {
                color: $danger;
            }
        }

        h2 {
            font-weight: 700;
            font-size: $font-26;
            text-align: center;
        }

        .day {
            color: $primary;
            margin-bottom: 20px;

            text-transform: lowercase;
        }
    }

    @include media($lg) {
        .timer-section {
            padding: 67px 50px;

            background-size: cover;

            .timer__wrapper {
                font-size: $font-80;

                .time {
                    width: 120px;
                }
            }

            h2 {
                font-size: $font-34;
            }

            .day {
                margin-bottom: 24px;
            }
            .loader {
                height: 290px;
            }
        }
    }
</style>
