<template>
    <div class="faq-section" v-if="faqData.length">
        <h3>{{ $t('main.faqSection.title') }}</h3>
        <p>{{ $t('main.faqSection.subTitle') }}</p>
        <Accordion>
            <div class="accordion-wrapper">
                <div class="accordion accordion-left">
                    <AccordionItem
                        v-for="faq in splittedArray[0]"
                        :key="faq.id"
                        :title="$filters.getTranslationByKey(faq.question)"
                        :text="$filters.getTranslationByKey(faq.answer)"
                    />
                </div>
                <div class="accordion accordion-right">
                    <AccordionItem
                        v-for="faq in splittedArray[1]"
                        :key="faq.id"
                        :title="$filters.getTranslationByKey(faq.question)"
                        :text="$filters.getTranslationByKey(faq.answer)"
                    />
                </div>
            </div>
        </Accordion>
        <CRButton @click="$router.push({ name: 'faq' })">{{ $t('buttons.allQuestions') }}</CRButton>
    </div>
</template>

<script>
    import Accordion from '@/components/Common/Accordion/Accordion.vue';
    import AccordionItem from '@/components/Common/Accordion/AccordionItem.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { FAQItemsApi } from '@/api';

    export default {
        name: 'FAQSection',
        components: { Accordion, AccordionItem, CRButton },
        data() {
            return {
                faqData: [],
                splittedArray: [],
            };
        },
        computed: {
            splittingValue() {
                if (this.faqData.length < 5) {
                    return 5;
                } else {
                    return Math.ceil(this.faqData.length / 2);
                }
            },
        },
        methods: {
            splitArray(array, n = this.splittingValue) {
                let [...arr] = array;
                const res = [];
                while (arr.length) {
                    res.push(arr.splice(0, n));
                }
                return res;
            },

            async fetchItems() {
                const response = await FAQItemsApi.getAll();
                this.faqData = response.data;
                this.splittedArray = this.splitArray(this.faqData.slice(0, 10), 5);
            },
        },
        async mounted() {
            await this.fetchItems();
        },
    };
</script>

<style lang="scss" scoped>
    .faq-section {
        width: 100%;

        h3 {
            margin-bottom: 10px;
        }

        p {
            font-weight: 700;
            opacity: 0.7;
            margin-bottom: 15px;
        }

        .accordion-wrapper {
            @include column-align-start;

            .accordion {
                width: 100%;
            }
        }
    }

    @include media($lg) {
        .faq-section {
            h3 {
                font-size: $font-24;
                margin-bottom: 8px;
            }

            .accordion-wrapper {
                @include row-align-start-justify-between;

                .accordion {
                    padding: 40px 0 30px 0;
                    display: block;
                    position: relative;
                    width: 50%;
                }

                .accordion-right {
                    padding-left: 70px;
                }

                .accordion-left {
                    padding-right: 70px;
                }

                .accordion-right::before,
                .accordion-left::before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 1px;
                    background: $grey;
                }

                .accordion-right::before {
                    left: 0px;
                }

                .accordion-left::before {
                    right: -1px;
                }
            }
        }
    }
</style>
